<template>
  <div>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">Trang chủ</li>
      <li class="breadcrumb-item">
        <a href="/admin/albums/lists">Quản lý album </a>
      </li>

      <li class="breadcrumb-item">Thêm mới album</li>
    </ol>

    <form
      method="post"
      @submit.prevent="mySubmit"
      id="sendform"
      v-if="!is_load"
    >
      <div class="container">
        <ul class="nav nav-tabs" role="tablist">
          <li class="nav-item">
            <a class="nav-link active" data-toggle="tab" href="#home"
              >Tiếng Việt
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#menu1">English </a>
          </li>
        </ul>

        <!-- Tab panes -->
        <div class="tab-content">
          <div id="home" class="container tab-pane active">
            <br />
            <div class="">
              <div class="col-sm-12">
                <div class="card">
                  <div class="card-header">
                    <strong>Thông tin </strong>
                    <small>Form</small>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-sm-10">
                        <div class="form-group">
                          <label for="name"
                            >Tên<span class="text-danger">*</span></label
                          >
                          <input type="text" name="name" class="form-control" />
                          <p class="text-danger">{{ error.name }}</p>
                        </div>
                      </div>
                      <div class="col-sm-10">
                        <div class="form-group">
                          <label for="picturefile"
                            >Hình ảnh đại diện<span class="text-danger"
                              >*</span
                            ></label
                          >
                          <input
                            type="file"
                            accept="image/*"
                            name="picturefile"
                            class="form-control main_picture"
                            @change="onPicturefile"
                          />

                          <div class="view_picture_one" v-if="img_one != ''">
                            <img :src="img_one" style="max-width: 400px" />
                          </div>
                          <p class="text-danger">{{ error.picturefile }}</p>
                        </div>
                      </div>
                      <div class="col-sm-10">
                        <div class="form-group">
                          <label for="multipicturefiles"
                            >Danh sách Hình ảnh<span class="text-danger"
                              >*</span
                            ></label
                          >
                          <input
                            type="file"
                            class="form-control main_picture"
                            name="multipicturefiles"
                            multiple
                            accept="image/*"
                            @change="onMutilPicturefile"
                          />
                          <div
                            class="view_picture_mutil"
                            v-if="img_mutil.length > 0"
                          >
                            <img
                              v-for="el in img_mutil"
                              :key="el"
                              :src="el"
                              class="mr-3 mt-3"
                              style="max-width: 200px"
                            />
                          </div>
                          <p class="text-danger">
                            {{ error.multipicturefiles }}
                          </p>
                        </div>
                      </div>
                      <div class="col-sm-10">
                        <div class="form-group">
                          <label for="content_EN"> Nội dung </label>
                          <ckeditor
                            :config="editorConfig"
                            v-model="content"
                            @namespaceloaded="onNamespaceLoaded"
                          ></ckeditor>
                        </div>
                      </div>
                      <div class="col-sm-10">
                        <div class="form-group">
                          <label for="name">Trạng thái </label>
                          <select class="form-control col-sm-3" name="status">
                            <option value="1">Hiện</option>
                            <option value="2">Ẩn</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="menu1" class="container tab-pane fade">
            <br />
            <div class="">
              <div class="col-sm-12">
                <div class="card">
                  <div class="card-header">
                    <strong>Album </strong>
                    <small>Form</small>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-sm-10">
                        <div class="form-group">
                          <label for="name_EN"
                            >Name <span class="text-danger">*</span></label
                          >
                          <input
                            type="text"
                            name="name_EN"
                            class="form-control"
                          />
                          <p class="text-danger">{{ error.name_EN }}</p>
                        </div>
                      </div>
                      <div class="col-sm-10">
                        <div class="form-group">
                          <label for="content_EN"> Content </label>
                          <ckeditor
                            :config="editorConfig_EN"
                            v-model="content_EN"
                            @namespaceloaded="onNamespaceLoaded"
                          ></ckeditor>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="card-footer"
            style="width: 90%; position: fixed; bottom: 0"
          >
            <button
              type="submit"
              class="btn btn-sm btn-primary"
              v-if="!is_load"
            >
              <i class="fa fa-save"></i> Save
            </button>
            <h5 v-else>Loading...</h5>
          </div>
        </div>
      </div>
    </form>

    <div v-else>
      <SkeletonTheme>
        <Skeleton height="100px" v-for="k in 5" :key="k"></Skeleton>
      </SkeletonTheme>
    </div>
  </div>
</template>

<script>
import { Skeleton, SkeletonTheme } from "vue-loading-skeleton";
import axios from "axios";

export default {
  data() {
    return {
      img_one: "",
      content: "",
      content_EN: "",
      img_mutil: [],
      is_load: false,
      error: {
        name: "",
        picturefile: "",
        multipicturefiles: "",
        name_EN: "",
      },
      editorConfig: window.editorConfig,
      editorConfig_EN: window.editorConfig_EN,
    };
  },

  components: {
    Skeleton,
    SkeletonTheme,
  },
  mounted() {},

  methods: {
    onNamespaceLoaded( CKEDITOR ) {
        CKEDITOR.plugins.addExternal( 'video', '/layout/ckeditor/video/plugin.js' );
    },
    alertError() {
      let tt = "";
      for (let i = 0; i < Object.values(this.error).length; i++) {
        if (Object.values(this.error)[i]) {
          tt =
            tt +
            "<ul style='text-align:left;'><li>" +
            Object.values(this.error)[i] +
            "</li></ul>";
        }
      }
      return tt;
    },
    mySubmit: function () {
      this.img_one = "";
      this.img_mutil = [];
      let myForm = document.getElementById("sendform");
      let formData = new FormData(myForm);
      for (var pair of formData) {
        if (pair[0] == "name") {
          if (pair[1] == "") {
            this.error.name = "Vui lòng nhập tên.";
          } else {
            this.error.name = "";
          }
        }
        if (pair[0] == "picturefile") {
          if (pair[1].name == "") {
            this.error.picturefile = "Vui lòng nhập hình.";
          } else {
            this.error.picturefile = "";
          }
        }
        if (pair[0] == "multipicturefiles") {
          if (pair[1].name == "") {
            this.error.multipicturefiles = "Vui lòng nhập multi picture.";
          } else {
            this.error.multipicturefiles = "";
          }
        }
        if (pair[0] == "name_EN") {
          if (pair[1] == "") {
            this.error.name_EN = "Vui lòng nhập tên - Tiếng Anh.";
          } else {
            this.error.name_EN = "";
          }
        }
      }
      if (
        this.error.name == "" &&
        this.error.picturefile == "" &&
        this.error.multipicturefiles == "" &&
        this.error.name_EN == ""
      ) {
        this.is_load = true;
        formData.append("content", this.content);
        formData.append("content_EN", this.content_EN);
        axios
          .post(window.my_api + "api/album/create-or-update-album", formData,
              {
                headers: window.my_header
              }
          )
          .then((res) => {
            if (res.status == 200) {
              alert("Thêm thành công.");
               this.$router.go(this.$router.currentRoute);
              document.getElementById("sendform").reset();
            } else {
              alert("Thêm không thành công, vui lòng liên hệ admin");
            }
            this.is_load = false;
          });
      } else {
      
        console.log(this.alertError());

        alert("Vui lòng đầy đủ dữ liệu ");
      }
    },
    onPicturefile(e) {
      let _this = this;
      var file = e.target.files[0];
      var reader = new FileReader();
      reader.onloadend = function () {
        _this.img_one = reader.result;
      };
      reader.readAsDataURL(file);
    },
    onMutilPicturefile(e) {
      let arr = [];
      let _this = this;
      Object.keys(e.target.files).forEach((i) => {
        let file = e.target.files[i];
        var reader = new FileReader();
        reader.onloadend = function () {
          arr.push(reader.result);
          _this.img_mutil = arr;
        };
        reader.readAsDataURL(file);
      });
    },
  },
};
</script>